@use "../variables.scss" as *;
@use "../mixins.scss";

.categories {
  &__wrapper {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
    justify-items: center;
    gap: 3rem;

    @include mixins.breakpoint-up(medium) {
      gap: 2rem;
    }

    .category__card {
      width: 17rem;
      height: auto;
      border-radius: $radius;
      display: grid;
      grid-template-columns: 1fr;
      justify-content: space-between;
      padding: 1rem 1.5rem 2rem 1.5rem;
      color: $textColor;
      box-shadow: $boxSh;

      .card__image {
        padding: 2px;

        .category__card-img {
          object-fit: fill;
          border-top-right-radius: $radius;
          border-top-left-radius: $radius;
        }
      }

      .category__card-body {
        height: 100%;
        padding: 1rem 0;
        align-self: start;

        & p {
          font-size: $fontSm;
          padding: 0.2rem 0;
        }
      }

      .card__footer {
        align-self: end;
        float: right;
      }
    }
  }
}

.category__products {
  &-container {
    padding-bottom: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    justify-content: center;
    gap: 3rem;

    .product__card {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      width: 18rem;
      height: 14rem;
      margin: 0 auto;
      color: $textColor;
      padding: 1rem 1rem 1rem 0;
      transition: all 0.1s ease;
      box-shadow: $boxSh;
      border-radius: $radius;

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 5%;
        background: rgba(249, 249, 146, 0.3);
        clip-path: polygon(15% 0%, 100% 10%, 80% 90%, 10% 61%);
        z-index: -99;
      }

      & p {
        font-size: $fontSm;
        font-weight: $weightLg;
      }

      &-left {
        position: absolute;
        top: 14%;
        left: -5%;
        height: 70%;
        padding: 0 0.7rem;
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.7rem;
        text-align: center;
        background-color: $whiteColor;
        box-shadow: $boxSh;
        border-radius: $radius;

        .product__card-image {
          margin: 0 auto;
        }

        .product__img {
          display: block;
          width: 80px;
          filter: drop-shadow(0 12px 8px rgba(0, 0, 0, 0.2));
          transition: transform 0.3s ease-in-out;
          cursor: pointer;

          &.image-clicked {
            transform: scale(1.7);
          }
        }
      }

      &-right {
        text-align: center;
        width: 50%;
        text-align: center;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.4rem;

        & p {
          font-size: $fontSm;
          color: $textColor;
        }
      }
    }
  }
}
