@use "./mixins";
@use "./variables" as *;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  font-size: 100%;
  scroll-behavior: smooth;
  width: 100%;
  min-height: 100%;
}
body {
  width: inherit;
  min-height: inherit;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  overflow-x: hidden;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}
ul {
  list-style: none;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1 {
  font-size: 2.5rem;
  @include mixins.breakpoint-up(medium) {
    font-size: 3rem;
  }
}
h2 {
  font-size: 2rem;
  @include mixins.breakpoint-up(medium) {
    font-size: 2.3rem;
  }
}
h3 {
  font-size: 1.5rem;
  @include mixins.breakpoint-up(medium) {
    font-size: 1.8rem;
  }
}
h4 {
  font-size: 1rem;
  @include mixins.breakpoint-up(medium) {
    font-size: 1.3rem;
  }
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

// REUSABLE CLASSES
.container {
  max-width: 64rem;
  margin-left: $mb-1-5;
  margin-right: $mb-1-5;

  @include mixins.breakpoint-down(small) {
    margin-left: $mb-1;
    margin-right: $mb-1;
  }

  @include mixins.breakpoint-up(xlarge) {
    margin-left: auto;
    margin-right: auto;
  }
}

.section {
  padding: 6.5rem 1rem;

  &__title {
    position: relative;
    margin-bottom: $mb-3;
    color: $textColor;
    text-transform: capitalize;
    letter-spacing: 2px;
    text-align: center;
  }
}
