@use "../variables.scss" as *;
@use "../mixins.scss";

.reset__password {
  .reset__form-bcg {
    width: 100%;
    height: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $textColor;

    .reset__title {
      padding-top: 1rem;
      display: flex;
      align-items: center;
      gap: 0.3rem;
      & h3 {
        text-transform: capitalize;
      }
    }

    .reset__form {
      max-width: 30rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 1rem;
      box-shadow: $boxSh;
      background: #ece9e6; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to left,
        #ffffff,
        #ece9e6
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to left,
        #ffffff,
        #ece9e6
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      @include mixins.breakpoint-up(medium) {
        row-gap: 1rem;
      }

      .form__control {
        margin: 0.5rem 0;

        .form__input {
          font-family: inherit;
          font-size: $fontSm;
          color: $textColor;
          width: 16rem;
          height: 2.5rem;
          outline: none;
          padding: 0 0.6rem;
          margin: 0.5rem 0;
          border: 3px solid transparent;
          transition: all 0.3s;
          border-radius: $radius;
          padding: 0.1rem 0.3rem;
          box-shadow: $boxSh;

          &:focus {
            outline: none;
          }

          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $textColorLight;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $textColorLight;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $textColorLight;
          }
        }
      }
    }
  }
}
