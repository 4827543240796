@use "../variables.scss" as *;
@use "../mixins.scss";

.product {
  margin: 0 auto;
  padding: 5rem 1rem;
  color: $textColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .product__header {
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid $lightColor;

    @include mixins.breakpoint-up(medium) {
      align-self: center;
      width: 50%;
    }
  }

  .product__image {
    align-self: self-start;
    margin-top: 1rem;
    padding: 1rem;
    background: transparent;
    box-shadow: $boxSh;

    @include mixins.breakpoint-up(medium) {
      margin-left: 25%;
    }

    & img {
      width: 200px;
    }
  }

  .product__details {
    padding: 1rem;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @include mixins.breakpoint-up(medium) {
      width: 50%;
    }
  }

  .product__rating {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .product__summary {
    padding: 0.5rem 0;
    line-height: 1.5;
  }

  .product__details-price {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    margin-bottom: $mb-0-5;
  }

  .product__details-packing-price {
    display: flex;
    align-items: center;
  }
}
