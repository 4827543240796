@use "../variables" as *;
@use "../mixins";

.orders {
  padding: 5rem 1rem;
  height: 100%;

  &__container {
    width: 100%;
    max-width: 64rem;
    height: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    gap: 2rem;
    justify-items: center;

    .order__card {
      background-color: $whiteColor;
      height: auto;
      width: 17rem;
      margin: 0 auto;
      padding: 1rem 1.5rem;
      transition: all 0.1s ease;
      box-shadow: $boxSh;
      font-size: $fontSm;
      color: $textColor;

      &-content {
        display: flex;
        flex-direction: column;
        padding: 1rem;

        & h4 {
          text-decoration: underline;
          text-transform: capitalize;
          margin-bottom: $mb-1;
        }
      }

      .order__item {
        margin-bottom: $mb-1;
        display: flex;
        flex-direction: column;
        gap: 3px;
        font-size: 0.7rem;
        outline-offset: 5px;
        outline: 1px solid $textColorLight;

        & img {
          width: 3rem;
        }

        &-name {
          font-weight: 500;
        }
      }

      .order__info {
        text-align: left;
        font-size: 0.7rem;
        width: 100%;
        margin: 0 1rem 1rem 1rem;

        & p {
          text-decoration: underline;
          margin-bottom: 0.3rem;
        }

        .order__data {
          display: flex;
          flex-direction: column;
          gap: 3px;
        }
      }
    }
  }
}
