@use "../variables" as *;
@use "../mixins";

.reviews {
  color: $textColor;
  & button {
    background: transparent;

    & img {
      display: block;
      object-fit: fill;
      width: 100px;
    }
  }
  .review__title {
    text-transform: capitalize;
    margin: $mb-1 0;
    text-align: center;
  }

  .review__container {
    margin: $mb-1 0;
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    justify-items: center;
    gap: 2rem;

    @include mixins.breakpoint-up(medium) {
      justify-items: start;
    }

    & .review__card {
      display: flex;
      flex-direction: column;
      padding: 1.5rem 1rem;
      box-shadow: $boxSh;

      .review__user-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        & img {
          width: 70px;
          height: 70px;
          object-fit: cover;
          border-radius: 50%;
        }

        .review__user {
          font-size: $fontMd;
        }
      }

      .review__content {
        padding: 1rem;
        text-align: left;
        font-size: $fontSm;
        font-weight: normal;
        text-transform: none;
        font-style: italic;
        line-height: 1.4;
      }

      .review__rating {
        display: flex;
        justify-content: center;
      }
    }
  }
}
