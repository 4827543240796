@use "../variables.scss" as *;
@use "../mixins.scss";

.dropdown__menu {
  display: grid;
  place-items: left;

  &-content {
    position: relative;
    width: max-content;
    transform: translateY(0);

    .dropdown__menu-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;

      .dropdown__menu-user-img {
        width: 30px;
        height: 30px;
        margin-right: 1px;
        object-fit: cover;
        color: $textColor;
        font-weight: normal;
        border-radius: 50%;

        @include mixins.breakpoint-up(medium) {
          height: 2rem;
          width: 2rem;
        }
      }

      .dropdown__menu-user {
        & p {
          background: $whiteColor;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          font-size: $fontMd;
          font-family: Arial, Helvetica, sans-serif;
          color: $textColor;
          font-weight: 500;
          margin-top: 5px;
        }
      }

      .dropdown__menu-icons {
        width: 24px;
        height: 24px;
        display: grid;
        place-items: center;
      }

      .dropdown__arrow {
        position: absolute;
        transition: opacity 0.1s, transform 0.4s;
        fill: $whiteColor;
        font-size: $fontSlg;
      }
    }

    .dropdown__list {
      background: $whiteColor;
      padding: 1rem 1.25rem;
      box-shadow: $boxSh;
      display: grid;
      row-gap: 1rem;
      position: absolute;
      width: 100%;
      left: 0;
      top: 3rem;
      transform: scale(0.1);
      transform-origin: 10rem -2rem;
      pointer-events: none;
      transition: opacity 0.4s, transform 0.4s;
      opacity: 0;

      .dropdown__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.2rem 0;
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: $mainGreen;
          .dropdown__title {
            color: $whiteColor;
          }
          .dropdown__icon {
            fill: $whiteColor;
          }
        }
      }

      .dropdown__icon {
        fill: $textColorLight;
        font-size: $fontMd;
      }

      .dropdown__title {
        font-weight: 500;
        color: $mainDark;
        text-transform: capitalize;
      }
    }
  }
}

/* Rotate & hide icon */
.show-dropdown .dropdown__close {
  opacity: 1;
  transform: rotate(-180deg);
}

.show-dropdown .dropdown__arrow {
  // opacity: 0;
  transform: rotate(180deg);
}

/* Show dropdown menu */
.show-dropdown .dropdown__list {
  opacity: 1;
  transform: scale(1);
  pointer-events: initial;
}
