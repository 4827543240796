@use "../variables.scss" as *;
@use "../mixins";

.shopping__cart {
  position: relative;
  height: auto;

  .sidebar__overlay {
    background-color: rgba($mainDark, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: end;
    z-index: 1;
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;

    &.shrink {
      transform: scaleY(0.01) scaleX(0);
    }

    &.expand {
      transform: scale(1);
      z-index: 110;
    }

    .close__cart {
      position: fixed;
      top: 10%;
      right: 85%;
      cursor: pointer;
      z-index: 111;
      color: $mainDark;
      background: transparent;
      font-weight: bold;
      padding: 0.3rem;
      border: none;
      filter: drop-shadow(1px 1px 1px $whiteColor);

      @include mixins.breakpoint-up(large) {
        top: 10%;
        right: 75%;
      }
    }
  }

  .sidebar-empty {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & h1 {
      text-transform: uppercase;
      color: $whiteColor;
      text-decoration: underline;
    }
  }

  .sidebar {
    overflow-y: auto;
    position: fixed;
    top: 8%;
    right: 0%;
    height: 100vh;
    width: 17rem;
    padding: 1rem;
    border: 1px solid $lightColor;
    box-shadow: $boxSh;
    background: #232526; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #414345,
      #232526
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #414345,
      #232526
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    & h3 {
      margin: 0 auto;
      width: 12rem;
      text-align: center;
      text-transform: uppercase;
      padding: 0.3rem 1rem;
      color: $whiteColor;
      background: $mainGreen;

      @include mixins.breakpoint-up(medium) {
        width: 17rem;
      }
    }

    @include mixins.breakpoint-up(medium) {
      width: 80%;
      height: auto;
      right: 1%;
    }

    @include mixins.breakpoint-up(large) {
      width: 65%;
    }

    .cart__item {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      gap: 0.5rem;
      margin: 1rem;
      color: $whiteColor;
      border: 1px solid $mainGreen;
      border-radius: $radius;

      @include mixins.breakpoint-up(medium) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 2rem;
      }

      & img {
        object-fit: cover;
        width: 5rem;
      }

      .name__packing-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .cart__item-name {
        text-decoration: underline;
      }

      .cart__item-name,
      .cart__item-packing,
      .cart__item-price,
      .cart__item-subtotal {
        font-size: $fontSm;
        font-weight: $weightMd;
        line-height: 1.5;
        justify-self: start;

        @include mixins.breakpoint-up(large) {
          font-size: $fontMd;
        }
      }

      .remove__item {
        color: $mainRed;
        cursor: pointer;
        background: transparent;
        filter: drop-shadow(1px 1px 0.1px $whiteColor);
      }

      .cart__item-column {
        display: flex;
        flex-direction: column;

        .quant__btn {
          width: 2rem;
          height: 2.5rem;
          background: transparent;
          cursor: pointer;
          align-self: center;
          border: none;
          z-index: 5;

          &:focus {
            outline: none;
          }
        }

        .quantity {
          text-align: center;
          margin: 0.2rem 0.5rem;
          padding-left: 0.1rem;
          font-size: $fontMd;
          font-weight: bold;
          line-height: 1;
        }

        .increase,
        .decrease {
          fill: $lightColor;
          font-size: 2rem;
          z-index: 100;
        }
      }
    }

    .shopping__cart-footer {
      padding: 1rem;
      text-align: center;

      @include mixins.breakpoint-up(medium) {
        text-align: right;
      }

      .shopping__cart-total {
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-top: $mb-1;
        padding-bottom: $mb-1;
        font-weight: bold;
        color: $whiteColor;
        border-bottom: 1px solid $lightColor;

        @include mixins.breakpoint-up(medium) {
          justify-content: flex-end;
        }
      }

      .button--purchase {
        background: $paypalColor;
        font-size: $fontSm;
        text-transform: capitalize;
      }

      .button--clear {
        margin-top: $mb-2;
        background: $mainRed;
        font-size: $fontSm;
        text-transform: capitalize;
      }
    }
  }
}
