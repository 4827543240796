@use "../variables.scss" as *;

.button {
  display: inline-block;
  background-color: $mainGreen;
  color: $whiteColor;
  padding: 1.25rem 2rem;
  font-weight: $weightMd;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  position: relative;
  box-shadow: $boxSh;
  border-radius: $radius;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease-in-out;
  }
  &:hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.5);
    transform: scale(1.2, 1.2);
  }

  &:hover::after {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.button--mid {
  background-color: $mainGreen;
  color: $whiteColor;
  padding: 1rem 1.5rem;

  &:hover {
    background-color: #a1a1a1;
  }
}

.button--small {
  padding: 0.9rem 1.5rem;
}

.button--xSmall {
  padding: 0.6rem 1rem;
}
