@use "../variables" as *;
@use "../mixins";

.footer {
  padding: 3rem 0;

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    & span {
      color: $textColor;
      font-size: $fontSm;
    }

    .footer__nav {
      .footer__nav-socials {
        display: flex;
        align-items: center;
        gap: 2rem;

        @include mixins.breakpoint-up(medium) {
          gap: 2rem;
        }
      }

      .footer__nav-item {
        color: $textColor;
      }
    }
  }
}
