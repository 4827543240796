@use "../variables.scss" as *;
@use "../mixins.scss";

.home {
  width: 100%;
  height: auto;
  padding: 5rem 0 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $textColor;

  &__container {
    display: grid;
    row-gap: 2rem;
    border-radius: $radius;
    box-shadow: $boxSh2;

    @include mixins.breakpoint-up(medium) {
      padding: 4rem 0 4rem 0;
      row-gap: 0;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      column-gap: 1rem;
    }

    .home__img-background {
      width: 100%;
      height: 100%;

      @include mixins.breakpoint-up(medium) {
        order: 2;
      }

      & .home__img {
        margin: 0 auto;
        width: 22rem;
        object-fit: cover;

        @include mixins.breakpoint-up(medium) {
          width: 100%;
          height: 100%;
          object-fit: contain;
          box-shadow: $boxSh;
        }
      }
    }

    .home__data {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding: 1rem;

      @include mixins.breakpoint-up(medium) {
        row-gap: 2rem;
      }

      .home__title {
        text-transform: capitalize;
      }

      .home__content {
        font-size: $fontSm;
        line-height: 1.4;

        @include mixins.breakpoint-up(medium) {
          font-size: $fontMd;
          line-height: 1.6;
        }
      }
    }
  }
}
